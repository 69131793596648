<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <b>Services </b>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            border
            :loading="loading"
            :items="services"
            :fields="fields"
            :items-per-page="25"
            clickable-rows
            :active-page="activePage"
            @row-clicked="rowClicked"
            :pagination="{ doubleArrows: false, align: 'center' }"
            @page-change="pageChange"
          >
            <td slot="category" slot-scope="{ item }">
              {{ item.category.name }}
            </td>
            <td slot="subcategory" slot-scope="{ item }">
              {{ item.subcategory.name }}
            </td>
            <td slot="base_price" slot-scope="{ item }">
              {{ item.base_price | currency100 }}
            </td>
            <td slot="price" slot-scope="{ item }">
              {{ item.price | currency100 }}
            </td>
            <td slot="created_at" slot-scope="{ item }">
              {{ item.created_at | moment(datetimeFormat) }}
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTION_FETCH_SERVICES } from '@/store/actions.type';
import { Common } from '@/constants';

export default {
  name: 'Services',
  data() {
    return {
      loading: true,
      datetimeFormat: Common.datetimeFormat,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'provider_id', label: 'ProviderID' },
        { key: 'category', label: 'Category' },
        { key: 'subcategory', label: 'Subcategory' },
        { key: 'price_type', label: 'PriceType' },
        { key: 'service_type', label: 'ServiceType' },
        { key: 'num_of_times', label: 'NumOfTimes' },
        { key: 'period', label: 'Period' },
        { key: 'num_of_units', label: 'NumOfUnits' },
        { key: 'unit_type', label: 'UnitType' },
        { key: 'base_price', label: 'BasePrice' },
        { key: 'price', label: 'Price' },
        { key: 'created_at', label: 'CreatedDate' },
      ],
      activePage: 1,
    };
  },
  mounted() {
    this.$store.dispatch(ACTION_FETCH_SERVICES).then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters(['services']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    rowClicked(item) {
      this.$router.push({ path: `services/${item.id}` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
